<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">单位管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">企业单位列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-input
                v-model="unitname"
                type="text"
                size="small"
                class="unitNamewidth"
                placeholder="请输入单位名称"
                clearable
              />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                clearable
                filterable
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
            <div title="联系人电话" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">联系人电话:</span>
              <el-input
                v-model="compPhone"
                type="text"
                size="small"
                placeholder="请输入联系人电话"
                clearable
              />
            </div>
            <div title="法人姓名" class="searchboxItem ci-full">
              <span class="itemLabel">法人姓名:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                placeholder="请输入法人姓名"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="单位类型" class="searchboxItem ci-full">
              <span class="itemLabel">单位类型:</span>
              <el-select
                v-model="compType"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="信用代码" class="searchboxItem ci-full">
              <span class="itemLabel">信用代码:</span>
              <el-input
                  v-model="compNo"
                  type="text"
                  size="small"
                  placeholder="请输入信用代码"
                  clearable
              />
            </div>
            <div title="市场负责人" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">市场负责人:</span>
              <el-select
                v-model="marketId"
                placeholder="请选择"
                size="small"
                clearable
                @change="
                  (val) => {
                    clean(val, 'saleName');
                  }
                "
              >
                <el-option
                  v-for="item in marketLeaderList"
                  :key="item.saleId"
                  :label="item.saleName"
                  :value="item.saleId"
                ></el-option>
              </el-select>
            </div>
            <div title="上级单位" class="searchboxItem ci-full">
              <span class="itemLabel">上级单位:</span>
              <el-select
                size="small"
                v-model="superiorCompId"
                remote
                :remote-method="getCompSourceList"
                filterable
                clearable
                placeholder="请输入两个字搜索"
              >
                <el-option
                  v-for="item in compSourceList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="入驻时间" class="searchboxItem ci-full">
              <span class="itemLabel">入驻时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="entryTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="btnBox">
              <el-button
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="add()">新增</el-button>
              <el-button class="bgc-bv" round @click="BatchImport()"
                >批量导入</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80"
              />
              <el-table-column
                label="单位名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="单位类型"
                align="left"
                prop="compType"
                show-overflow-tooltip
                min-width="80"
              >
               <template slot-scope="{row}">
                  {{$setDictionary('COMPANYTYPE',row.compType)}}
               </template>
              </el-table-column>
              <el-table-column
                  label="信用代码"
                  align="left"
                  prop="compNo"
                  show-overflow-tooltip
                  min-width="120"
              />
              <el-table-column
                label="上级单位"
                align="left"
                prop="compParentName"
                show-overflow-tooltip
                min-width="180"
              >
                <template slot-scope="scope">
                  {{ scope.row.compParentName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="所属行政区划"
                align="left"
                prop="areaNamePath"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="联系人电话"
                align="left"
                prop="compPhone"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.compPhone || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="法人姓名"
                align="left"
                prop="compLegal"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.compLegal || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="市场负责人"
                align="left"
                prop="marketName"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.marketName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="入驻时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                width="100px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="220px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleEdit(scope.row.compId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleAdd(scope.row.compId)"
                    >新增</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="GenerateQRcode(scope.row)"
                    >生成二维码</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="zUnbind(scope.row)"
                    >解除绑定</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="生成二维码"
      :visible="generateQRcodeImgLoading"
      :before-close="doClose"
      width="500px"
    >
      <div ref="pic_content">
        <img style="width: 100%" :src="generateQRcodeImg" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="generateQRcodeImgLoading = false" size="small"
          >关 闭</el-button
        >
        <!-- <el-button
          class="bgc-bv"
          @click="downloadGenerateQRcode(generateQRcodeImg,'6666')"
          size="small"
          >下 载</el-button
        > -->
      </span>
    </el-dialog>
    <!-- 批量导入 -->
    <el-dialog
      title="批量导入"
      :visible.sync="dialogFormVisible"
      @close="closeDocsDialog"
      width="50%"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="7rem"
        class="commodity-form"
      >
        <el-form-item label="行政区划" prop="areaId" class="form-item">
          <el-cascader
            clearable
            filterable
            v-model="ruleForm.areaId"
            :options="areatreeList"
            :props="propsarea"
            size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="下载模板" class="form-item">
          <el-button @click="downloadExcel">下载</el-button>
        </el-form-item>
        <el-form-item label="上传单位" class="form-item">
          <el-upload
              class="upload-demo upload-workers"
              style="margin-top: 6px;"
              :action="actionUrl"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-change="uploadChangeNew"
              :show-file-list="false"
              :auto-upload="false"
          >
            <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
            >浏览</el-button
            >
          </el-upload>
          <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
            当前选择文件：
            <span style="color: #f46173">{{ fileName }}</span>
          </p>
          <p v-else style="margin-top: 10px; margin-left: 10px">
            未选择文件
          </p>
          <div>
            <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top: 10px; height: 35px"
                :disabled="doExportInDisabled"
                @click="doExport"
            >开始导入</el-button
            >
          </div>
          <div style="margin-top: 10px" v-if="progressVal > 0">
            <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="progressVal"
            ></el-progress>
          </div>
        </el-form-item>
        <el-form-item label="错误数据" class="form-item">
          <div class="df studentNum">
            <span>导入已完成，共:{{ totalNum }}个单位;</span><span>成功:{{ correctNum }}个;</span><span>失败:{{ errorNum }}个;</span>
          </div>
          <div>
            <el-button
                class="bgc-bv"
                style="margin-top: 6px; height: 35px"
                size="mini"
                :disabled="errorNum == '0'"
                @click="doExportError"
            >导出错误数据</el-button
            >
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败单位，请导出错误数据，将导入失败单位调整后重新上传
          </div>
        </el-form-item>
<!--        <div class="export-box">-->
<!--          <h1>-->
<!--            2-->
<!--            <span>导入编写好的Excel文档</span>-->
<!--          </h1>-->
<!--          <div class="flexdc">-->
<!--            <div class="df" style="margin: 20px 0 10px">-->
<!--              <el-upload-->
<!--                  class="upload-demo upload-workers"-->
<!--                  :action="actionUrl"-->
<!--                  :on-error="handleError"-->
<!--                  :on-success="handleSuccess"-->
<!--                  :on-change="uploadChangeNew"-->
<!--                  :show-file-list="false"-->
<!--                  :auto-upload="false"-->
<!--              >-->
<!--                <el-button class="bgc-bv" style="font-size: 12px; width: 80%"-->
<!--                >浏览</el-button-->
<!--                >-->
<!--              </el-upload>-->
<!--              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">-->
<!--                当前选择文件：-->
<!--                <span style="color: #f46173">{{ fileName }}</span>-->
<!--              </p>-->
<!--              <p v-else style="margin-top: 10px; margin-left: 10px">-->
<!--                未选择文件-->
<!--              </p>-->
<!--            </div>-->
<!--            <div>-->
<!--              <el-button-->
<!--                  class="bgc-bv"-->
<!--                  size="mini"-->
<!--                  style="margin-top: 10px; height: 35px"-->
<!--                  :disabled="doExportInDisabled"-->
<!--                  @click="doExport"-->
<!--              >开始导入</el-button-->
<!--              >-->
<!--            </div>-->
<!--            <div style="margin-top: 10px" v-if="progressVal > 0">-->
<!--              <el-progress-->
<!--                  :text-inside="true"-->
<!--                  :stroke-width="20"-->
<!--                  :percentage="progressVal"-->
<!--              ></el-progress>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="export-box">-->
<!--          <h1>-->
<!--            3-->
<!--            <span>导入结果</span>-->
<!--          </h1>-->
<!--          <div class="df studentNum">-->
<!--            <span>学员总数量:{{ totalNum }}人;</span>-->
<!--            <span>成功:{{ correctNum }}人;</span>-->
<!--            <span>失败:{{ errorNum }}人;</span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <el-button-->
<!--                class="bgc-bv"-->
<!--                style="margin-top: 15px; height: 35px"-->
<!--                size="mini"-->
<!--                :disabled="errorNum == '0'"-->
<!--                @click="doExportError"-->
<!--            >导出错误数据</el-button-->
<!--            >-->
<!--          </div>-->
<!--          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">-->
<!--            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传-->
<!--          </div>-->
<!--        </div>-->
<!--        <el-form-item label="上传单位" prop="fileName">-->
<!--          <el-upload-->
<!--            v-model="ruleForm.fileName"-->
<!--            v-if="!ruleForm.fileName"-->
<!--            class="upload-demo upload-btn"-->
<!--            :action="actionUrl"-->
<!--            :on-error="handleError"-->
<!--            :on-success="handleSuccess"-->
<!--            :on-change="uploadChange"-->
<!--            :show-file-list="false"-->
<!--            :auto-upload="false"-->
<!--          >-->
<!--            <el-button size="small" class="bgc-bv">选取文件</el-button>-->
<!--          </el-upload>-->
<!--          <span style="color: #f46173" v-else>-->
<!--            {{ ruleForm.fileName }}-->
<!--            <a @click="reomveExl" style="color: #5bb5ff">删除</a>-->
<!--          </span>-->
<!--        </el-form-item>-->
      </el-form>
<!--      <div slot="footer" class="dialog-footer flexcc">-->
<!--        <el-button class="bgc-bv" @click="closeDocsDialog">取消</el-button>-->
<!--        <el-button class="bgc-bv" @click="formSave('ruleForm')">保存</el-button>-->
<!--      </div>-->
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "UnitList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 行政区划 - 下拉数据
      areatreeList: [],
      // 行政区划 - 规定字段
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 单位类型 - 下拉数据
      companyList: [],
      // 上级单位 - 下拉数据
      compSourceList: [],
      compType: "",
      areaId: "",
      unitname: "",
      compPhone: "",
      compName: "",
      superiorCompId: "",
      compNo: "",
      entryTime: [], //入驻时间,
      marketId: "",
      search: {
        saleName: true,
      },
      // 生成二维码的 - loading
      generateQRcodeImgLoading: false,
      // 生成的二维码
      generateQRcodeImg: "",
      dialogFormVisible: false, //批量导入弹窗是否显示
      ruleForm: {
        areaId: "",
        excelUrl: "",
        fileName: "",
      },
      rules: {
        areaId: [
          { required: true, trigger: "blur", message: "请选择行政区划" },
        ],
        fileName: [
          { required: true, message: "请上传单位模版", trigger: "blur" },
        ],
      },

      fileName: "",
      fileKey: "",
      // projectName: '--',
      // centerDialogVisible: false,
      // templatelist: "", // 模板数据
      doExportInDisabled: true, //开始导入按钮禁用
      progressVal: 0,
      numText: '',
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      errorExcelUrl: '',
    };
  },
  created() {
    this.getCompSourceList();
    this.getareatree();
    this.getcompanyList();
    this.getMarketLeader();

    // this.getTemplate(this.projectId);
  },
  computed: {},
  methods: {
    // getTemplate(projectId) {
    //   this.$post("/biz/project/user/v2/template", {
    //     projectId,
    //   }).then((ret) => {
    //     this.templatelist = ret.data || [];
    //   });
    // },
    // downloadExcelgs(templatePath) {
    //   /**
    //    * 下载模板
    //    * @param param 参数
    //    */
    //   let link = document.createElement("a");
    //   link.style.display = "none";
    //   link.href = templatePath;
    //   let templateNames = templatePath.slice(8);
    //   console.log(templateNames);
    //   link.setAttribute("download", templateNames);
    //   document.body.appendChild(link);
    //   link.click();
    // },
    // 导入学员
    uploadChangeNew(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
          .then((result) => {
            // console.log(result);
            this.fileKey = result.data.fileKey;
            this.doExportInDisabled = false;
            // this.fileName = result.data.fileKey;
          })
          .catch((err) => {
            return;
          });
    },
    doExport() {
      const el = this.$refs.ruleForm;
      el.validate((valid) => {
        if (valid) {
          if (this.fileKey == "") {
            this.$message({
              message: "请选择文件",
              type: "warning",
            });
          } else {
            // this.$confirm("是否将此表中学员绑定此班级?", "提示", {
            //   confirmButtonText: "确定",
            //   confirmButtonClass: "confirmButtonClass",
            //   cancelButtonText: "取消",
            //   type: "warning",
            // })
            // .then(() => {
            this.doExportInDisabled = true;
            if (this.fileKey) {
              const parmar = {
                excelUrl: this.fileKey,
                areaId: this.ruleForm.areaId,

              };
              this.$post("/sys/company/importCompany", parmar, 5000)
                  .then((res) => {
                    if (res.status == 0) {
                      if (res.status == 0) {
                        console.log(res.message);
                        this.doProgress(res.message);
                        this.doExportInDisabled = false;
                      }
                    }
                  })
                  .catch(() => {
                    return;
                  });
            } else {
              this.doExportInDisabled = false;
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
            // })
            // .catch(() => {
            //   return;
            // });
          }
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });

    },
    //获取导入进度
    /* /biz/projectUser/importProgress */
    doProgress(batchId) {
      this.$post(
          "/sys/company/import/progress",
          { batchId },
          5000
      ).then((ret) => {
        if (!ret.data.status) {
          // this.progressVal = ret.data.progress;
          // this.totalNum = ret.data.totalNum;
          // this.errorNum = ret.data.errorNum;
          // this.correctNum = ret.data.correctNum;
          // this.importType = ret.data.importType;
          // this.batchId = ret.data.batchId;
          // this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId, projectId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
            this.numText = ret.data.message
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.errorExcelUrl = ret.data.excelUrl
          } else {
            this.numText = ret.data.message
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        // this.$post(
        //     "/biz/project/user/v2/error/export",
        //     { batchId: this.batchId, projectId: this.projectId },
        //     5000
        // ).then((res) => {
        //   if (res.status == 0) {
            window.open(this.errorExcelUrl);
          // }
        // });
      }
    },
    closeDocsDialog() {
      // this.centerDialogVisible = false;
      this.newvalues = [];
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.totalNum = 0;
      this.errorNum = 0;
      this.correctNum = 0;
      this.doExportInDisabled = true;
      // this.doExportInDisabled = false;
      this.dialogFormVisible = false;
      this.ruleForm = {
        areaId: "",
        excelUrl: "",
        fileName: "",
      };
      this.getData(-1);
    },
    // 行政区划 - 下拉数据
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 单位类型 - 下拉数据
    getcompanyList() {
      const companyList = this.$setDictionary("COMPANYTYPE", "list");
      let companylist = [{ value: "", label: "全部" }];
      for (const key in companyList) {
        companylist.push({
          value: key,
          label: companyList[key],
        });
      }
      this.companyList = companylist;
    },
    //获取市场负责人
    getMarketLeader(saleType) {
      this.$post("/biz/user/sale/selectList", { saleType: "10" }).then(
        (ret) => {
          this.marketLeaderList = ret.data || [];
        }
      );
    },
    // 上级单位
    getCompSourceList(query) {
      if (query && query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.compSourceList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 选择市场负责人
    clean(val, name) {
      this[name] = val;
      this.search[name] = false;
    },
    /* 新增 */
    add() {
      this.$router.push({
        path: "/web/UnitEdit",
        query: { stu: "add" },
      });
    },
    /* 列表的新增 */
    handleAdd(compId) {
      this.$router.push({
        path: "/web/UnitEdit",
        query: { compId, stu: "addsubordinate" },
      });
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      if (this.search.saleName) {
        this.marketId = this.$route.query.saleId;
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.unitname) {
        params.compName = this.unitname;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.compType) {
        params.compType = this.compType;
      }
      if (this.compPhone) {
        params.compPhone = this.compPhone;
      }
      if (this.compName) {
        params.compLegal = this.compName;
      }
      if (this.marketId) {
        params.marketId = this.marketId;
      }
      if (this.superiorCompId) {
        params.superiorCompId = this.superiorCompId;
      }
      if (this.compNo) {
        params.compNo = this.compNo;
      }

      if (this.entryTime) {
        params.startDate = this.entryTime[0];
        params.endDate = this.entryTime[1];
      }
      this.doFetch({
        url: "/sys/company/page-list",
        params,
        pageNum,
      });
    },
    // 单位编辑
    handleEdit(compId) {
      this.$router.push({
        path: "/web/UnitEdit",
        query: { compId, stu: "edit" },
      });
    },
    // 生成二维码
    GenerateQRcode(row) {
      this.$post("biz/subscribeProject/createSubscribeCompanyQRCode", {
        compId: row.compId,
      }).then((res) => {
        this.generateQRcodeImgLoading = true;
        this.generateQRcodeImg = res.data;
      });
    },
    // 下载生成二维码
    downloadGenerateQRcode(imgsrc, name) {
      this.$message({
        message: "此功能暂时延期，请别提bug",
        type: "warning",
      });
      // let image = new Image();
      // // 解决跨域 Canvas 污染问题
      // image.setAttribute("crossOrigin", "anonymous");
      // image.onload = function() {
      //   let canvas = document.createElement("canvas");
      //   canvas.width = image.width;
      //   canvas.height = image.height;
      //   let context = canvas.getContext("2d");
      //   context.drawImage(image, 0, 0, image.width, image.height);
      //   let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      //   let a = document.createElement("a"); // 生成一个a元素
      //   let event = new MouseEvent("click"); // 创建一个单击事件
      //   a.download = name || "photo"; // 设置图片名称
      //   a.href = url; // 将生成的URL设置为a.href属性
      //   a.dispatchEvent(event); // 触发a的单击事件
      // };
      // image.src = imgsrc;

      // const fileName= `${name}.png`;
      // const myBlob = new Blob([imgsrc], { type: "image/png" });
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(myBlob);
      // link.download = fileName;
      // link.click();
      // link.remove();
      // URL.revokeObjectURL(link.href);

      // let link = document.createElement("a");
      // link.style.display = "none";
      // link.href = "https://p5.toutiaoimg.com/origin/pgc-image/SNISfitBmdzyEz?from=pc.png";
      // link.setAttribute("download", "push_temp.png");
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // this.generateQRcodeImgLoading = false;
      // this.downloadIamge('https://p5.toutiaoimg.com/origin/pgc-image/SNISfitBmdzyEz?from=pc.png')
    },
    // 关闭生成二维码
    doClose(done) {
      this.generateQRcodeImgLoading = false;
    },
    // 解除绑定
    zUnbind(row) {
      this.$router.push({
        path: "/web/zUnbind",
        query: { compId: row.compId },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
    //批量导入
    BatchImport() {
      this.dialogFormVisible = true;
      // this.centerDialogVisible = true;

    },
    // 下载模板
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/单位信息导入模板.xlsx";
      link.setAttribute("download", "单位信息导入模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    //上传单位模版
    // uploadChange(file) {
    //   let size = file.size / 1024 / 1024;
    //   // let fileName = file.name.split(".");
    //   let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
    //   const isXLSX = extension === "XLSX";
    //   const isxlsx = extension === "xlsx";
    //   const isXLS = extension === "XLS";
    //   const isxls = extension === "xls";
    //   if (!isXLSX && !isxlsx && !isXLS && !isxls) {
    //     this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
    //     return;
    //   }
    //   if (size > 1) {
    //     this.$message.error("文件大小不能超过1M");
    //     return;
    //   }
    //   this.ruleForm.fileName = file.name;
    //   let formData = new FormData();
    //   formData.append("folder ", "QUESTION");
    //   formData.append("file ", file.raw);
    //   formData.append("fileType ", extension);
    //   this.$Postformat("/sys/upload", formData)
    //     .then((result) => {
    //       this.ruleForm.excelUrl = result.data.fileKey;
    //       this.$refs["ruleForm"].clearValidate("fileName");
    //     })
    //     .catch(() => {
    //       setTimeout(() => {
    //         this.$message({
    //           type: "warning",
    //           message: "保存失败",
    //         });
    //       }, 300);
    //     });
    //   this.$forceUpdate();
    // },
    //移除文件
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    // 保存
    // formSave(formName = "ruleForm") {
    //   const el = this.$refs[formName];
    //   el.validate((valid) => {
    //     if (valid) {
    //       this.doAjax();
    //     } else {
    //       this.$message({
    //         type: "warning",
    //         message: "请输入必填项",
    //       });
    //     }
    //   });
    // },
    // doAjax() {
    //   let params = {
    //     areaId: this.ruleForm.areaId,
    //     excelUrl: this.ruleForm.excelUrl,
    //   };
    //   if (this.ruleForm.excelUrl == "") {
    //     this.$message.warning("请先上传单位模版");
    //     return false;
    //   }
    //   this.$post("/sys/company/importCompany", params)
    //     .then(() => {
    //       setTimeout(() => {
    //         this.$message({
    //           type: "success",
    //           message: "操作成功",
    //         });
    //         this.closeDocsDialog();
    //         this.getData(-1)
    //       }, 300);
    //     })
    //     .catch((err) => {
    //       return;
    //     });
    // },

  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.unitname = ""),
            (this.areaId = ""),
            (this.compType = ""),
            (this.compPhone = ""),
            (this.compName = ""),
            (this.pageNum = 1);
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.upload-btn {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.searchbox {
  padding: 0;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
//.exportdialog {
  /deep/ .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
//}
.studentNum {
  margin: 0 0 10px;
  span {
    margin: 0 10px;
  }
}
</style>